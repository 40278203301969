<script lang="ts" setup>
import { ref, computed, type PropType, defineAsyncComponent, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import useSearchSuggestion from "~/composables/useSearchSuggestion";
import {
  type SuggestionResultSection,
  type RestaurantSearchSuggestion,
} from "~/api/common/searchSuggestion";
const SuggestionView = defineAsyncComponent(
  () => import("./SuggestionView.vue")
);
const props = defineProps({
  container: {
    type: String as PropType<"default" | "float">,
    default: "float",
  },
  isHybrid: {
    type: Boolean,
    default: false,
  },
  hideOnClickOutside: {
    type: Boolean,
    default: true,
  },
  searchKeyword: {
    type: String,
    default: "",
  },
});

const emit = defineEmits<{
  (e: "on-tag-clicked", data: SuggestionResultSection): void;
  (e: "on-restaurant-clicked", data: RestaurantSearchSuggestion): void;
}>();

const restaurantSearch = ref(null);

const {
  submitHandler,
  inputHandler,
  toggleHiddenSuggestion,
  focusHandler,
  isLoading,
  isAnyResult,
  isSuccess,
  keyword,
  suggestions,
  toggleHiddenResult,
  isShowSuggestion,
} = useSearchSuggestion();

onClickOutside(restaurantSearch, () => {
  if (props.hideOnClickOutside) {
    toggleHiddenSuggestion(false);
  }
});

const tagSuggestions = computed(() => {
  return {
    cuisines: suggestions.cuisines,
    locations: suggestions.locations,
    diningStyles: suggestions.diningStyles,
    mrtRoutes: suggestions.mrtRoutes,
    btsRoutes: suggestions.btsRoutes,
    shoppingMalls: suggestions.shoppingMalls,
    facilities: suggestions.facilities,
  };
});

watch(
  () => props.searchKeyword,
  (value) => {
    if (value !== keyword.value) {
      submitHandler(value);
    }
  }
);
</script>

<template>
  <div ref="restaurantSearch" class="relative w-full lg:mx-0">
    <slot
      name="input-view"
      :handler="{
        submitHandler,
        inputHandler,
        toggleHiddenSuggestion,
        focusHandler,
      }"
      :state="{ isLoading, isSuccess, isAnyResult, keyword }"
    ></slot>
    <slot
      name="suggestion-view"
      :state="{ keyword }"
      :suggestions="{
        restaurants: suggestions.restaurants,
        locations: suggestions.locations,
        cuisines: suggestions.cuisines,
        diningStyles: suggestions.diningStyles,
        mrtRoutes: suggestions.mrtRoutes,
        btsRoutes: suggestions.btsRoutes,
        facilities: suggestions.facilities,
        shoppingMalls: suggestions.shoppingMalls,
      }"
      :handler="{ toggleHiddenResult }"
      :meta="{ isLoading, isSuccess, isAnyResult, isShowSuggestion }"
    >
      <SuggestionView
        v-show="isShowSuggestion"
        :is-error="!isSuccess"
        :is-loading="isLoading"
        :is-any-result="isAnyResult"
        :suggestions="tagSuggestions"
        :restaurants="suggestions.restaurants.data"
        :show-all-restaurants="suggestions.restaurants.isShowAll"
        :container="container"
        :is-hybrid="isHybrid"
        @on-view-more-restaurants="suggestions.restaurants.isShowAll = true"
        @on-toggle-result="(data) => toggleHiddenResult(data)"
        @on-tag-clicked="(data) => emit('on-tag-clicked', data)"
        @on-restaurant-clicked="(data) => emit('on-restaurant-clicked', data)"
      />
    </slot>
  </div>
</template>
